
textarea{
    resize: none;
}

.form{
    &_container{
        width: clamp(300px,60vw, 600px);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    &_inputs{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        &>input{            
         height: 44px;
         border: 1px solid rgb(161, 161, 161);
         border-radius: 10px;
         padding: 0 0 0 10px;
         &::placeholder{
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 2px;
         }
        }

    }
    &_comments{
        width: 100%;
        &>textarea{
            width: 100%;
            box-sizing: border-box;
            height: 170px;
            border-radius: 10px;
            border: 1px solid rgb(161, 161, 161);
            padding: 10px;
            &::placeholder{
                font-family: 'Montserrat', sans-serif;
                letter-spacing: 2px;
             }
        }
    }

}

.submit_button{
    margin-top: 20px;
    -webkit-appearance: none;
    opacity: 1;

    &>input{
        padding: 10px 40px 10px 40px;
        background-color: #e77320;
        ;
        border: none;
        border-radius: 5px;
        color:whitesmoke;
        -webkit-appearance: none;
        opacity: 1;
        letter-spacing: 2px;
        &:hover{
            cursor: pointer;
            filter: brightness(1.2);
            transition: all .3s ease;
        }
    }
}

.contact-title{
    color: black;
    font-size: 1.5rem;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .form{
        &_container{
            grid-template-columns: 1fr;
        }
        &_inputs{
            width: 100%;
        }
    }

  }

  .bad {
      border: 2px solid red !important;
  }