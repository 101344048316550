.displayed_image{
    display: flex;
    justify-content: center;
    max-width: 80%;  
    &>*{
        object-fit: fill;
        max-width: 100%;
        &>*{
            object-fit: fill;
            max-width: 100%;
        &>img{
            max-width: 100%;
        }
    }
    }
}

.attribute_container{
    width: 300px;
    display: flex;
    flex-direction: column;    
    height: 100px;
    justify-content: space-between;
    margin: 30px auto;
    text-align: center;
    &>div{
        color: rgb(161, 161, 161);
    }
    &>a{
        background-color: #234099;
        padding: 5px;
        border-radius: 20px;
        text-decoration: none;
        color: whitesmoke;
        &:hover{
            filter: brightness(1.5);
        }
    }
}



.carousel_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.forward{
    text-align: right;
    
}

.back, .forward{
    &>svg{
        &:hover{
            cursor: pointer;
        }
    }
}

.titles{
    font-size: 1.5rem;
    color: black;    
    text-align: center;
}

.mt-5{
    margin-top: 10px;
    font-style: italic;
}