.navbar{
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;
    // border: 1px solid;
    &_container{
        width: min(800px, max(265px, Calc(60%)));
        display: flex;
        justify-content: space-between;
        // border: 1px solid;

        &>div{
            // border: 1px solid red;
            width: fit-content;
            text-align: center;
            &>a{
                text-decoration: none;
                text-transform: uppercase;
                &:hover{
                    color: #e77320;
                }
            }
        }
    }

}

.active{
    color: #d8620e;
}

a {
    color: rgb(66, 66, 66);
}

@media only screen and (max-width: 1100px) {
    .navbar{
        &_container{        
            &>div{
                // border: 1px solid green; 
                &>a{
                    letter-spacing: 0px !important;
                     font-size: .8rem;
                }
            }
        }
    

    }
}