html{
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.3px;
}

.border{
    border: 1px solid;
}

.header{
    text-align: end;
    margin: 30px auto;
}

.page_container{
    width: clamp(300px,60vw, 756px);
    margin: 30px auto;
}

h1{
    text-transform: uppercase;
    color: #e77320;
}

@media only screen and (max-width: 1000px) {
    .header{
        text-align: center;
    }
}


