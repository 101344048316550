.file-input{
    border: none !important;
}

.admin-function{
    width: 100%;
    text-align: center;
    &>button{
        border: none;
        padding: 10px;
        border-radius: 10px;
        background-color: cornflowerblue;
        color: white;
        &:hover{
            cursor: pointer;
        }
    }
}

.img-edit{
    height: 100px;
    width: 100px;
}

.edit-paintings-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;    
    justify-content: center;    
    &>*{
        margin: 20px;
        border: 1px solid;
        width: 300px;        
        text-align: center;
        &>*{
            margin: 10px;
            
            &>*{
                display: flex;
                justify-content: space-between;
                margin: 10px;
                &>label{
                    vertical-align: center;                    
                }
                & > input{
                    width: 50%;
                }
                & > textarea {
                    width: 51%;
                    height: 150px;
                }

                
            }
        }
    }
}

.delete-painting{
    background-color: rgb(200, 4, 4);
    color: white;
    border: none;
    padding: 5px 30px 5px 50px;
    border-radius: 10px;
    width: 50%;
    text-align: center !important;    
    &:hover{
        cursor: pointer;
        opacity: .6;
    }
}


.edit-painting-button{
    background-color: rgb(24, 181, 0);
    color: white;
    border: none;
    padding: 5px 30px 5px 35px;
    border-radius: 10px;
    width: 50%;
    text-align: center !important;    
    &:hover{
        cursor: pointer;
        opacity: .6;
    }
}