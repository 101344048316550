.gallery-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    max-width: 756px;
    
    .navigation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      cursor: pointer;
      
      &:hover {
        opacity: 0.8;
      }
    }
    
    .nav-arrow {
      font-size: 24px;
    }
  }
  
  .image-container {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  
  .attribute-container {
    text-align: center;
    margin-top: 2rem;
    
    .title {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    
    .dimensions {
      font-size: 1rem;
      color: #666;
      margin-bottom: 0.5rem;
    }
    
    .comment {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
  
  .create-link {
    position: fixed;
    bottom: 10px;
    left: 10px;
    font-size: 10px;
    z-index: 10;
    
    a {
      text-decoration: none;
      color: inherit;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  // Media Queries
  @media only screen and (max-width: 1100px) {
    .carousel-wrapper {
      max-width: 600px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .carousel-wrapper {
      width: 100%;
    }
    
    .navigation-button {
      width: 40px;
      height: 40px;
    }
  }
  
  // Loader styles
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  
  // Error styles
  .error-container {
    text-align: center;
    padding: 2rem;
    color: #ff0000;
  }