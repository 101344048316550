.page-container {
    width: 80%;
    margin: 30px auto;
    @media screen and (max-width: 768px) {
        width: 90%;
        display: block;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto 0 0 auto;
      }
  }

  .dissappearing-link{
    @media screen and (max-width: 768px) {
        display: none !important;
    }
  }
  
  .bio-container {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
  }
  
  .profile {
    height: 250px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        margin: auto;
      } 
  }
  
  .bio-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* This evenly spaces the elements */
    width: 70%;  
  
    @media screen and (max-width: 768px) {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 10px;
      margin: auto;
      > h3 {
        margin-bottom: 0px;
      }
      > * {
        padding-top: 10px;
      }
    }  
  }
  
  .bio-header a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .bio-content, .awards {
    width: 80%;
    @media screen and (max-width: 768px) {
        margin: auto;
      }  
  }
  
  .bio-p1 {
    margin-bottom: 15px;
  }
  
  .quadrant {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  
  .quadrant h2 {
    font-size: 18px;
    margin-top: 0;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  .artist-statement {
    width: 80%;    
    @media screen and (max-width: 768px) {
        margin: auto;
      }  
  }
  
  .artist-statement h1 {
    font-size: 24px;
  }
  
  .artist-statement p {
    margin-bottom: 15px;
  }
  
  